import { useEffect, useState } from "react";
import "./App.css";

function App() {
  const [state, setState] = useState({
    msg: null,
    weather: null,
    loading: true,
  });

  useEffect(() => {
    loadMessage(setState);
  }, [setState]);

  return (
    <div className="App">
      <header className="App-header">
        {state.loading ? (
          <p className="loading">mvm is druk typende</p>
        ) : (
          <div>
            <h1
              onClick={(ev) => {
                navigator.clipboard.writeText(state.msg);
              }}
              className="groet"
            >
              {state.msg}
            </h1>
            <p>{state.weather.samenv}</p>
            <p>{state.weather.temp} ℃</p>
          </div>
        )}
      </header>
    </div>
  );
}

function loadMessage(callback) {
  fetch("/api/")
    .then((x) => x.json())
    .then((x) => {
      let emoji = mapToEmoji(x.image);
      let msg = "Mogge " + emoji;
      setTimeout(() => {
        document.title = `${emoji} Mogge ${emoji}`;
        callback({
          msg: msg,
          weather: x,
          loading: false,
        });
      }, 2000);
    });
}

function mapToEmoji(image) {
  if (["buien", "regen", "hagel"].includes(image)) return "🌧️";

  if (["zwaarbewolkt", "bewolkt", "wolkennacht"].includes(image)) return "☁️";

  if (["mist", "nachtmist"].includes(image)) return "🌫️";

  switch (image) {
    case "halfbewolkt":
      return "⛅";
    case "lichtbewolkt":
      return "🌤️";
    case "zonnig":
      return "🌞";
    case "bliksem":
      return "⛈️";
    case "helderenacht":
      return "🌙";
    case "sneeuw":
      return "🌨️";
    default:
      return "🌞";
  }
}

export default App;
